import { useQuery } from '@tanstack/react-query'
import { PUBLIC_APP_URL } from '~/core/constants/env'

export const downloadResume = async (data: {
  shareLink: string
  id: string
}) => {
  const response = await fetch(
    `/api/resume/${data.id}/${data.shareLink.replace(
      `${PUBLIC_APP_URL}/share/cv/`,
      ''
    )}/download`
  )
  const jsonFormat = await response.json()
  return jsonFormat
}

export const useResumeDownload = (params: {
  shareLink: string
  id: string
}) => {
  const { error, isLoading, data } = useQuery({
    queryKey: [`resume-download`, { id: params.id }],
    queryFn: () =>
      downloadResume({ shareLink: params.shareLink, id: params.id })
  })

  return { url: data?.url, isLoading, error }
}
