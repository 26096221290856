import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { SocialButton } from '~/core/ui/SocialButton'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'

const ShareCVModal: FC<{
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  url?: string
}> = ({ open, setOpen, url = '' }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const handleCopyToClipboard = useCallback(() => {
    setIsCopied(true)
    navigator.clipboard.writeText(url)

    setTimeout(() => setIsCopied(false), 1500)
  }, [url])

  return (
    <Dialog
      open={open}
      size="sm"
      onOpenChange={setOpen}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      label={`${t('modal:share_modal:title')}`}
      headingClassName="tablet:pb-0">
      <TypographyText className="mb-5 mt-1 text-sm text-gray-600">{`${t(
        'modal:share_modal:description'
      )}`}</TypographyText>
      <div className="mb-5">
        <TypographyText className="mb-2 text-sm font-medium text-gray-900">
          {t('modal:share_modal:copy_link')}
        </TypographyText>

        <InputGroup>
          <Input
            size="sm"
            className="!border-gray-300 pr-[78px]"
            isDisabled={true}
            value={url}
          />
          <InputRightElement className="!bottom-0 !right-0 !top-0">
            <Tooltip
              content="Link copied"
              open={isCopied}
              classNameConfig={{ content: isCopied ? '' : 'hidden' }}>
              <Button
                type="secondary"
                className="rounded-l-none focus:!ring-0 focus:!ring-offset-0"
                label={`${t('button:copy')}`}
                iconMenus="Copy"
                size="sm"
                onClick={handleCopyToClipboard}
              />
            </Tooltip>
          </InputRightElement>
        </InputGroup>
      </div>
      <TypographyText className="mb-2 text-sm font-medium text-gray-900">
        {t('modal:share_modal:share_on')}
      </TypographyText>

      <div className="flex space-x-3">
        <FacebookShareButton url={url}>
          <SocialButton size="md" type="facebook" />
        </FacebookShareButton>

        <LinkedinShareButton url={url}>
          <SocialButton size="md" type="linkedin" />
        </LinkedinShareButton>

        <TwitterShareButton url={url}>
          <SocialButton size="md" type="twitter" />
        </TwitterShareButton>
      </div>
    </Dialog>
  )
}

export default ShareCVModal
